<template>
  <div>
    <KTCard title="Bill List">
      <template v-slot:toolbar>
        <b-button
          @click="openModal('createBillModal')"
          variant="primary"
          size="sm"
          ><i class="fa fa-plus"></i> Create Bill</b-button
        >
      </template>

      <template v-slot:body>
        <b-row v-if="!!tableSettings">
          <b-col cols="6">
            <b-form-group class="m-2" label-size="sm">
              <template v-if="tableSettings.refresh">
                <b-button size="sm" class="mr-2">
                  <i class="fa fa-sync"></i> Refresh
                </b-button>
              </template>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="m-2"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
            >
              <template v-if="tableSettings.filter">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-form-group>
          </b-col>
        </b-row>

        <b-table
          :per-page="tableSettings.perPage"
          :current-page="tableSettings.currentPage"
          :filter="filter"
          striped
          show-empty
          bordered
          hover
          :items="BillList"
          :fields="fields"
        >
          <template v-slot:cell(actions)="row">
            <b-button-group size="sm">
              <b-button
                @click="onPermission(row.item)"
                variant="primary"
                size="sm"
              >
                Permissions
              </b-button>
              <b-button @click="onRemove(row.item)" variant="danger" size="sm">
                Remove
              </b-button>
            </b-button-group>
          </template>
        </b-table>

        <b-pagination
          v-if="tableSettings.row > tableSettings.perPage"
          class="pl-0"
          v-model="currentPage"
          :total-rows="tableSettings.rows"
          :per-page="tableSettings.perPage"
        ></b-pagination>
      </template>
    </KTCard>

    <b-modal
      @ok="handleOk"
      ok-title="Submit"
      size="md"
      id="addBillModal"
      title="Add Bill User"
    >
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";

import { validationMixin } from "vuelidate";
import {
  CREATE_BILL,
  GET_BILL_STATUS,
} from "@/core/services/store/modules/clientadmin/billing.module";

export default {
  mixins: [validationMixin],
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Client Admin" },
      { title: "Bill List" },
    ]);

    let payload = {
      length: 100,
      start: 0,
    };

    this.$store.dispatch(GET_BILL_STATUS, payload);
  },
  validations: {
    form: {},
  },
  computed: {
    tableSettings() {
      const list = this.$store.state.clientadmin_billing.billStatus;
      let perPage = 20;
      let listLength;
      if (Array.isArray(list) && list.length) {
        listLength = list.length;
      } else {
        listLength = 100;
      }
      return {
        row: listLength,
        perPage: perPage,
        refresh: false,
        filter: true,
      };
    },
    BillList() {
      const list = this.$store.state.clientadmin_billing.billStatus;
      console.log(list);
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      selectedBill: null,
      form: {},
      filter: "",
      currentPage: 1,
      fields: [],
    };
  },

  methods: {
    onRemove(bill) {
      // assign selected bill
      this.selectedBill = bill;

      // open permission modal
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.submitForm();
    },
    submitForm() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      } else {
        console.log("submit");
        let payload = this.form;
        this.$store.dispatch(CREATE_BILL, payload).then((resp) => {
          if (resp.response_code === 2100) {
            console.log("CREATE_BILL SUCCESS");
          } else {
            console.log("CREATE_BILL FAILED");
          }
        });
      }
    },
  },
};
</script>
